import React from "react";
import MainBoard from "pages/MainBoard/MainBoard";

function App() {
  return (
    <>
      <MainBoard/>
    </>
  );
}

export default App;
